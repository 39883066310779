import axios from "axios";
import { reportError } from "@/utils/error-reporting";
import API from "@/modules/Project/classes/API";

class ProjectUnitAPI extends API {
    async getAllUnits({ queries = {}, page = 1, perPage = 15 }) {
        try {
            let include = "agencyUser,projectUnitType,projectUnitFloorPlan";

            let response = await axios.get(
                `${ProjectUnitAPI.apiURL}/project-units`,
                {
                    params: {
                        ...queries,
                        include: include,
                        page: page,
                        limit: perPage
                    }
                }
            );
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitAPI.panelName}] Get Project Units`
            );
            throw error;
        }
    }
    async getAssignedUnits({ queries = {}, page = 1, perPage = 15 }) {
        try {
            let include = "project,projectUnitFloorPlan,projectUnitType";

            let response = await axios.get(
                `${ProjectUnitAPI.apiURL}/project-units/agent`,
                {
                    params: {
                        ...queries,
                        include: include,
                        page: page,
                        limit: perPage
                    }
                }
            );
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitAPI.panelName}] Get Project Assigned Units`
            );
            throw error;
        }
    }
    async getUnit(id) {
        let include = "projectUnitType,projectUnitFloorPlan";

        try {
            let response = await axios.get(
                `${ProjectUnitAPI.apiURL}/project-units/${id}?include=${include}`
            );
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitAPI.panelName}] Get Project Unit (#${id})`
            );
            throw error;
        }
    }
    async createUnit(payload) {
        try {
            let response = await axios.post(
                `${ProjectUnitAPI.apiURL}/project-units`,
                payload
            );

            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitAPI.panelName}] Create Project Unit`
            );
            throw error;
        }
    }
    async updateUnit({ id, payload }) {
        try {
            let response = await axios.put(
                `${ProjectUnitAPI.apiURL}/project-units/${id}`,
                payload
            );
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitAPI.panelName}] Update Project Unit (#${id})`
            );
            throw error;
        }
    }
    async updateStatus({ id, payload }) {
        try {
            let response = await axios.put(
                `${ProjectUnitAPI.apiURL}/project-units/${id}/status`,
                payload
            );
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitAPI.panelName}] Update Project Unit Status (#${id})`
            );
            throw error;
        }
    }
    async updateSalePrice({ id, payload }) {
        try {
            let response = await axios.patch(
                `${ProjectUnitAPI.apiURL}/project-units/${id}/sale-price`,
                payload
            );
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitAPI.panelName}] Update Project Unit Price (#${id})`
            );
            throw error;
        }
    }
    async deleteUnit(id) {
        try {
            const response = await axios.delete(
                `${ProjectUnitAPI.apiURL}/project-units/${id}`
            );

            return response;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitAPI.panelName}] Delete Project Unit (#${id})`
            );
            throw error;
        }
    }

    // ================================= BANKER ================================
    async getUnassignedBankers(
        unitId,
        { queries = {}, page = 1, perPage = 15 }
    ) {
        let URL = `${ProjectUnitAPI.apiURL}/project-units/${unitId}/available-banker`;
        try {
            const response = await axios.get(URL, {
                params: {
                    ...queries,
                    page: page,
                    limit: perPage
                }
            });
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitAPI.panelName}] Get Unassigned Bankers (Project #${unitId})`
            );
            throw error;
        }
    }
    async getAssignedBankers(unitId, { queries = {}, page = 1, perPage = 15 }) {
        let URL = `${ProjectUnitAPI.apiURL}/project-units/${unitId}/assigned-banker`;
        try {
            const response = await axios.get(URL, {
                params: {
                    ...queries,
                    page: page,
                    limit: perPage
                }
            });
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitAPI.panelName}] Get Assigned Bankers (Project #${unitId})`
            );
            throw error;
        }
    }
    async addAssignedBankers(unitId, payload) {
        let URL = `${ProjectUnitAPI.apiURL}/project-units/${unitId}/add-banker`;
        try {
            const response = await axios.post(URL, payload);
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitAPI.panelName}] Update Assigned Bankers (Project #${unitId})`
            );
            throw error;
        }
    }
    async removeAssignedBanker(unitId, payload) {
        let URL = `${ProjectUnitAPI.apiURL}/project-units/${unitId}/remove-banker`;
        try {
            const response = await axios.delete(URL, { data: payload });
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitAPI.panelName}] Remove Assigned Bankers (Project #${unitId})`
            );
            throw error;
        }
    }
    // ================================= LAWYER ================================
    async getUnassignedLawyers(
        unitId,
        { queries = {}, page = 1, perPage = 15 }
    ) {
        let URL = `${ProjectUnitAPI.apiURL}/project-units/${unitId}/available-lawyer`;
        try {
            const response = await axios.get(URL, {
                params: {
                    ...queries,
                    page: page,
                    limit: perPage
                }
            });
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitAPI.panelName}] Get Unassigned Lawyers (Project #${unitId})`
            );
            throw error;
        }
    }
    async getAssignedLawyers(unitId) {
        let URL = `${ProjectUnitAPI.apiURL}/project-units/${unitId}/assigned-lawyer`;
        try {
            const response = await axios.get(URL);
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitAPI.panelName}] Get Assigned Lawyers (Project #${unitId})`
            );
            throw error;
        }
    }
    async updateAssignedLawyers(unitId, payload) {
        let URL = `${ProjectUnitAPI.apiURL}/project-units/${unitId}/update-lawyer`;
        try {
            const response = await axios.put(URL, payload);
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectUnitAPI.panelName}] Update Assigned Lawyers (Project #${unitId})`
            );
            throw error;
        }
    }
}

export default ProjectUnitAPI;
