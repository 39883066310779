import { isStringEmpty } from "@/utils/string";
import { convertNullIfEmpty } from "@/utils/null-check";
class ProjectUnitModel {
    static postPayload(e) {
        return {
            projectId: e.projectId || "",
            projectUnitTypeId: e.projectUnitTypeId || "",
            projectFloorPlanId: e.projectFloorPlanId || "",
            street: e.street || "",
            orientation: e.orientation || "",
            plot: e.plot || [],
            projectPhaseId: e.projectPhaseId || "",

            bankerIds: e.bankerIds || [],
            spaLawyerId: e.spaLawyerId || "",
            laLawyerId: e.laLawyerId || ""
        };
    }

    static putPayload(e) {
        return {
            street: e.street || "",
            description: e.description || "",
            salePrice: e.salePrice || "0",
            salePricePerUnit: !isStringEmpty(e.salePricePerUnit)
                ? e.salePricePerUnit.toString()
                : "0",
            gallery: e.gallery || [],
            vrLink: e.vrLink || "",
            status: e.status || "",
            projectPhaseId: e.projectPhaseId || "",
            orientation: e.orientation || "",
            lotTypeId: e.lotTypeId || "",
            furnishingType: e.furnishingType || "",
            tenureType: convertNullIfEmpty(e.tenureType) || [],

            landWidth: !isStringEmpty(e.landWidth)
                ? e.landWidth.toString()
                : "0",
            landDepth: !isStringEmpty(e.landDepth)
                ? e.landDepth.toString()
                : "0",
            landArea: !isStringEmpty(e.landArea) ? e.landArea.toString() : "0",

            builtUpWidth: !isStringEmpty(e.builtUpWidth)
                ? e.builtUpWidth.toString()
                : "0",
            builtUpDepth: !isStringEmpty(e.builtUpDepth)
                ? e.builtUpDepth.toString()
                : "0",
            builtUpArea: !isStringEmpty(e.builtUpArea)
                ? e.builtUpArea.toString()
                : "0",

            bookingFee: e.bookingFee || "",
            titleDeed: e.titleDeed || [],
            additionalDocument: e.additionalDocument || []
        };
    }

    static getToEditResponse(e) {
        return {
            street: e.street || "",
            description: e.description || "",
            salePrice: convertNullIfEmpty(e.salePrice) || "",
            salePricePerUnit: convertNullIfEmpty(e.salePricePerUnit) || "",
            gallery: e.gallery || [],
            vrLink: e.vrLink || "",
            status: e.status || "",
            projectPhaseId: e.phase ? e.phase.id : "",
            orientation: e.orientation || "",
            lotTypeId: e.lotType ? e.lotType.id : "",
            furnishingType: e.furnishingType || "",
            tenureType: convertNullIfEmpty(e.tenureType) || [],

            landWidth: convertNullIfEmpty(e.landWidth) || "",
            landDepth: convertNullIfEmpty(e.landDepth) || "",
            landArea: convertNullIfEmpty(e.landArea) || "",
            builtUpWidth: convertNullIfEmpty(e.builtUpWidth) || "",
            builtUpDepth: convertNullIfEmpty(e.builtUpDepth) || "",
            builtUpArea: convertNullIfEmpty(e.builtUpArea) || "",

            areaUnitType: e.projectUnitFloorPlan.areaUnitType || "",
            propertyTypeId: e.projectUnitType.propertyType.id || "",
            isEdit: e.isEdit || false,
            bookingFee: e.bookingFee || "",
            isEditBookingFee: e.isEditBookingFee || false,
            titleDeed: e.titleDeed || [],
            additionalDocument: e.additionalDocument || []
        };
    }
}

export default ProjectUnitModel;
