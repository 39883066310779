<template>
  <div class="manage-units">
    <div class="row justify-content-end">
      <button v-if="canAdd" class="btn main" @click="openAddUnitModal">
        <i class="fas fa-plus"></i>
        <span class="d-none sm-d-inline-block ml-1">Add</span>
      </button>
      <button
        :class="{ active: isFilterActive }"
        class="btn toggle ml-1"
        @click="filterToggle"
      >
        <i class="fas fa-filter"></i>
      </button>
    </div>

    <!-- Filter -->
    <filter-panel
      v-model="isFilterActive"
      bordered
      class="filter my-2"
      @clear="clearFilter"
      @filter="filterData"
    >
      <div class="row sm-p-2">
        <fd-input
          v-model="filter['street[partial]']"
          class="col-12 px-1 mb-2"
          label="Street"
          name="filterUnitStreet"
          type="text"
          @keyup.enter.native="filterData"
        >
        </fd-input>
      </div>
    </filter-panel>

    <!-- Content -->
    <div class="py-2">
      <!-- Spinner -->
      <div
        v-if="isLoading"
        class="spinner-container d-flex justify-content-center align-items-center"
      >
        <spinner></spinner>
      </div>
      <div v-else>
        <no-data
          v-if="units.length < 1"
          message="Oops, there is no unit available for now."
        ></no-data>

        <div v-else>
          <!-- unit cards -->
          <div class="row justify-content-start">
            <div
              v-for="unit in units"
              :key="unit.id"
              class="col-12 sm-col-6 lg-col-4 mb-4"
            >
              <unit-card
                class="m-1"
                :projectId="projectId"
                :unit="unit"
                :canEdit="canEdit"
                :canDelete="canDelete"
                :canAssignBanker="canEdit"
                :canAssignLawyer="canEdit"
                @delete="deleteUnit"
                @edit="openEditUnitModal"
                @change-status="openChangeStatusModal"
                @assign-banker="openAssignBankerModal"
                @assign-lawyer="openAssignLawyerModal"
                @toDetail="toUnitDetail"
              ></unit-card>
            </div>
          </div>
        </div>
      </div>

      <!-- pagination -->
      <fd-page
        v-show="units.length > 0"
        class="text-center pt-2"
        v-model="paginationParams.page"
        :total-row="unitPagination.total"
        :perPage="paginationParams.perPage"
        @page-change="pageChange"
      ></fd-page>
    </div>

    <!-- ======================== Modal: Add New ========================= -->
    <modal v-model="addUnitModal.isShown" fullscreen>
      <add-unit
        :type="type"
        :projectId="projectId"
        :projectUnitTypeId="unitTypeId"
        :projectFloorPlanId="floorPlanId"
        @cancel="addUnitModal.isShown = false"
        @submit="createUnit"
      ></add-unit>
    </modal>
    <!-- ========================== Modal: Edit ============================ -->
    <modal v-model="editUnitModal.isShown" fullscreen>
      <edit-unit
        :type="type"
        :projectId="projectId"
        :projectUnitTypeId="unitTypeId"
        :unitId="editUnitModal.unitId"
        @cancel="editUnitModal.isShown = false"
        @submit="updateUnit"
      ></edit-unit>
    </modal>
    <!-- ====================== Modal: Change Status ======================= -->
    <modal v-model="changeStatusModal.isShown">
      <change-unit-status
        :unit="changeStatusModal.unit"
        @cancel="changeStatusModal.isShown = false"
        @submit="changeStatus"
      ></change-unit-status>
    </modal>
    <!-- ====================== Modal: Assign Banker ======================= -->
    <modal v-model="bankerAssign.isModalShown" fullscreen>
      <UpdateBankerAllocation
        class="card"
        :type="type"
        :projectId="projectId"
        :unit="bankerAssign.unit"
        @close="bankerAssign.isModalShown = false"
      ></UpdateBankerAllocation>
    </modal>
    <!-- ====================== Modal: Assign Lawyer ======================= -->
    <modal v-model="lawyerAssign.isModalShown" fullscreen>
      <UpdateLawyerAllocation
        class="card"
        :type="type"
        :projectId="projectId"
        :unit="lawyerAssign.unit"
        @close="lawyerAssign.isModalShown = false"
      ></UpdateLawyerAllocation>
    </modal>
  </div>
</template>

<script>
import ProjectUnitModel from "@/modules/Project/models/ProjectUnitModel";
import ManagerRoles from "@/modules/Project/classes/ManagerRoles";
import ProjectUnitAPI from "@/modules/Project/api/projectUnit";

export default {
  components: {
    UnitCard: () => import("@/modules/Project/components/Unit/UnitCard"),
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    AddUnit: () => import("@/modules/Project/components/Unit/AddUnit"),
    EditUnit: () => import("@/modules/Project/components/Unit/EditUnit"),
    ChangeUnitStatus: () =>
      import("@/modules/Project/components/Unit/ChangeUnitStatus"),
    UpdateBankerAllocation: () =>
      import("@/modules/Project/components/AssignBankers/UpdateUnitAllocation"),
    UpdateLawyerAllocation: () =>
      import("@/modules/Project/components/AssignLawyers/UpdateUnitAllocation")
  },
  mixins: [],
  props: {
    type: {
      type: String,
      validator: (val) => new ManagerRoles().getRoles().includes(val)
    },
    projectId: {
      type: [Number, String]
    },
    unitTypeId: {
      type: [Number, String]
    },
    floorPlanId: {
      type: [Number, String]
    },
    canAdd: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isLoading: false,

      units: {},
      unitPagination: {},

      // Filter Related
      isFilterActive: false,
      filter: {
        "street[partial]": ""
      },
      // Pagination
      paginationParams: {
        page: 1,
        perPage: 12
      },
      // Modals
      addUnitModal: {
        isShown: false
      },
      editUnitModal: {
        unitId: "",
        isShown: false
      },
      changeStatusModal: {
        unit: {},
        isShown: false
      },
      bankerAssign: {
        unit: {},
        bankers: [],
        isModalShown: false
      },
      lawyerAssign: {
        unit: {},
        lawyers: {},
        isModalShown: false
      },

      projectUnitAPI: new ProjectUnitAPI(this.type)
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      this.isLoading = true;
      await Promise.all([this.getUnits()]);
      this.isLoading = false;
    },
    openAddUnitModal() {
      this.addUnitModal.isShown = true;
    },
    openEditUnitModal(id) {
      this.editUnitModal.unitId = id;
      this.editUnitModal.isShown = true;
    },
    openChangeStatusModal(unit) {
      this.changeStatusModal.unit = unit;
      this.changeStatusModal.isShown = true;
    },
    openAssignBankerModal(unit) {
      this.bankerAssign.unit = unit;
      this.bankerAssign.isModalShown = true;
    },
    openAssignLawyerModal(unit) {
      this.lawyerAssign.unit = unit;
      this.lawyerAssign.isModalShown = true;
    },

    toUnitDetail(unitTypeId) {
      this.$router.push({
        name: "ManageProjectUnitsDetails",
        params: { projectId: this.projectId, id: unitTypeId }
      });
    },

    // ===================== Filter related methods =====================
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    async pageChange(info) {
      this.isLoading = true;
      this.paginationParams.page = info.pageNumber;
      await this.getUnits();
      this.isLoading = false;
    },
    async filterData() {
      this.isLoading = true;
      this.paginationParams.page = 1;
      await this.getUnits();
      this.isLoading = false;
    },
    async clearFilter() {
      if (this.$isFilterEmpty(this.filter)) {
        return;
      } else {
        this.isLoading = true;
        this.paginationParams.page = 1;
        this.filter = this._.mapValues(this.filter, () => null);
        await this.getUnits();
        this.isLoading = false;
      }
    },
    // ====================== API related methods ======================
    async getUnits() {
      try {
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await this.projectUnitAPI.getAllUnits({
          queries: {
            "project:id": this.projectId,
            "projectUnitFloorPlan:id": this.floorPlanId,
            ...filteredParam
          },
          page: this.paginationParams.page,
          perPage: this.paginationParams.perPage
        });
        this.units = this._.cloneDeep(data.data);
        this.unitPagination = this._.cloneDeep(data.meta.pagination);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        throw error;
      }
    },

    async createUnit(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.projectUnitAPI.createUnit(
          ProjectUnitModel.postPayload(payload)
        );
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Unit has been created successfully."
        });
        this.addUnitModal.isShown = false;
        await this.getUnits();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: error.response.data.detail
        });
        throw error;
      }
    },

    async updateUnit(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.projectUnitAPI.updateUnit({
          id: id,
          payload: payload
        });
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Unit has been updated successfully."
        });
        this.editUnitModal.isShown = false;
        await this.getUnits();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: error.response.data.detail
        });
        throw error;
      }
    },

    async changeStatus(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.projectUnitAPI.updateStatus({
          id: id,
          payload: payload
        });
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Unit status has been updated successfully."
        });
        this.changeStatusModal.isShown = false;
        await this.getUnits();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        throw error;
      }
    },

    async deleteUnit(id, plot) {
      let confirm = await this.$confirm({
        type: "alert",
        message: `Are you sure you want to delete unit (Plot ${plot})? This action cannot be undone.`
      });
      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await this.projectUnitAPI.deleteUnit(id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Unit has been deleted successfully."
          });
          await this.getUnits();
          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "An unexpected error occured. Please try again later."
          });
          throw error;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.manage-units {
  .spinner-container {
    min-height: 300px;
  }
  .filter {
    box-shadow: 0px 2px 10px -2px #00000025;
  }
}
</style>
